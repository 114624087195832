import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstsService {
  // public URL_DEVELOPMENT = 'http://localhost:3000/';
  public URL_DEVELOPMENT = 'https://track-hour-server.herokuapp.com/';
  public URL_DEPLOYMENT = 'https://track-hour-server.herokuapp.com/';
  constructor() {}
}
