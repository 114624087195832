import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/shared/user.service';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ShiftService } from 'src/app/services/shift-service/shift.service';
import { Observable } from 'rxjs';
import { MomentService } from 'src/app/services/moment/moment.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {
  constructor(private momentService: MomentService) {}
  @Input() chartType = 'bar';
  @Input() user: User;
  @Input() onChangeUser: Observable<any[]>;

  public chartDatasets: Array<any>;
  data: any[] = [];
  public chartLabels: Array<any> = [];
  public chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(105, 0, 132, .2)',
      borderColor: 'rgba(200, 99, 132, .7)',
      borderWidth: 2
    },
    {
      backgroundColor: 'rgba(0, 137, 132, .2)',
      borderColor: 'rgba(0, 10, 130, .7)',
      borderWidth: 2
    }
  ];

  public chartOptions: any = {
    responsive: true
  };
  setCharts() {
    this.chartDatasets = [
      { data: this.data, label: 'זמן במשמרת' }
      // { data: [28, 48, 40, 19, 86, 27, 90], label: 'My Second dataset' }
    ];
  }

  ngOnInit() {
    this.onChangeUser.subscribe((currentUser: any[]) => {
      this.chartLabels = [];
      this.data = [];
      const usableData = currentUser.map(shift => {
        const date = this.momentService.getDateFormat(shift.date);
        const hours = parseFloat(
          this.momentService.getShiftTime(shift.checkIn, shift.checkOut)
        );
        return { date: date, hours: hours };
      });
      let index = 0;
      let indexRemove = 1;
      let length = usableData.length;
      for (let shift = 0; shift < length - 1; shift++) {
        if (usableData[index].date == usableData[index + 1].date) {
          usableData[index].hours += usableData[index + 1].hours;
          usableData.splice(indexRemove, 1);
        } else {
          index++;
          indexRemove++;
        }
      }
      usableData.map(shift => {
        this.chartLabels.push(shift.date);
        this.data.push(shift.hours);
      });

      this.setCharts();
    });
  }
  public chartClicked(e: any): void {}
  public chartHovered(e: any): void {}
}
