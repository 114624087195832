import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserComponent } from './user/user.component';
import { LoginComponent } from './user/login/login.component';
import { RegisterComponent } from './user/register/register.component';
import { UserService } from './shared/user.service';
import { ActivationComponent } from './user/activation/activation.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './home/header/header.component';
import { ShiftService } from './services/shift-service/shift.service';
import { UserShiftsComponent } from './home/reports/user-shifts/user-shifts.component';
import { ClockManagerComponent } from './home/clock-manager/clock-manager.component';
import { FooterComponent } from './footer/footer.component';
import { PersonalComponent } from './home/personal/personal.component';
import { MapBoxComponent } from './UI/map-box/map-box.component';
import { AdminComponent } from './admin/admin.component';
import { HeaderAdminComponent } from './admin/header-admin/header-admin.component';
import { ChartComponent } from './UI/chart/chart.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    UserComponent,
    ActivationComponent,
    HomeComponent,
    HeaderComponent,
    UserShiftsComponent,
    ClockManagerComponent,
    FooterComponent,
    PersonalComponent,
    MapBoxComponent,
    AdminComponent,
    HeaderAdminComponent,
    ChartComponent
  ],
  imports: [
    MDBBootstrapModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      progressBar: true
    }),
    NgxLocalStorageModule.forRoot(),
    MDBBootstrapModule.forRoot()
  ],
  providers: [UserService, ShiftService],
  bootstrap: [AppComponent]
})
export class AppModule {}
