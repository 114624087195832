import * as moment from 'moment';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ShiftService } from 'src/app/services/shift-service/shift.service';
import { Subject } from 'rxjs';
import { MomentService } from 'src/app/services/moment/moment.service';

@Component({
  selector: 'app-user-shifts',
  templateUrl: './user-shifts.component.html',
  styleUrls: ['./user-shifts.component.css']
})
export class UserShiftsComponent implements OnInit {
  sallary_per_hour = 30;
  activeUser: User;
  userShifts: User;
  monthlyShifts = [];
  eventChangeMonth = new Subject<any[]>();
  constructor(
    private authService: AuthService,
    public shiftService: ShiftService,
    public momentService: MomentService
  ) {}

  ngOnInit() {
    // Get active user
    this.activeUser = this.authService.getActiveUser();

    // get shift hours
    this.getAllShiftHours();

    // Get all user shifts
    this.userShifts = this.shiftService.getUserShifts();
  }

  getAllShiftHours() {
    this.activeUser.shifts.map(shift => {
      if (shift.checkOut) {
        shift.shiftTime = parseFloat(
          this.momentService.getShiftTime(shift.checkIn, shift.checkOut)
        ).toFixed(2);
      } else {
        return;
      }
    });
  }

  // when change month
  onChangeMoth(val) {
    this.monthlyShifts = this.activeUser.shifts.filter(shift => {
      return (
        this.momentService.getMountFormat(shift.date) ===
        this.shiftService.selectedMonth.toString()
      );
    });
    this.shiftService.selectedMonth.toString();

    this.eventChangeMonth.next(this.monthlyShifts);
  }

  getTotalShiftsTime(): number {
    let sum = 0;
    this.monthlyShifts.map(shift => {
      if (shift.checkOut) {
        sum += parseFloat(shift.shiftTime);
      } else {
        return;
      }
    });
    return sum;
  }

  getShiftsReports() {
    this.shiftService.getUserReport(this.monthlyShifts).subscribe(
      res => {},
      err => {}
    );
  }

  getSalary(): number {
    return (
      parseInt(this.getTotalShiftsTime().toFixed(2)) * this.sallary_per_hour
    );
  }

  getShiftLocation(data) {
    console.log(this.monthlyShifts[data]);
    let place = [this.monthlyShifts[data]];
    this.eventChangeMonth.next(place);
  }

  getAllShiftsLocations() {
    this.eventChangeMonth.next(this.monthlyShifts);
  }
}
