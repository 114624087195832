import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { UserService } from 'src/app/shared/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'ngx-localstorage';
import { NavigateService } from 'src/app/services/navigate/navigate.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isWorking = false;

  constructor(
    public service: UserService,
    private toastr: ToastrService,
    private storageService: LocalStorageService,
    private navigate: NavigateService
  ) {}

  ngOnInit() {}

  onLoginClick() {
    // this method change isWork bool to true when try to login
    this.startWork();

    // call to api post and active subscribe to login
    this.service.login().subscribe(
      (res: any) => {
        const userJson = JSON.stringify(res.user);
        this.storageService.set('activeUser', userJson);
        const user = JSON.parse(userJson);
        if (user.role === 'user') {
          // this.router.navigateByUrl('/home');
          this.navigate.navigateTo('/home');
        } else if (user.role === 'admin') {
          // this.router.navigateByUrl('/admin');
          this.navigate.navigateTo('/admin');
        }
        this.service.loginModel.reset();

        // this method change isWork bool to false when finish to login
        this.stopWork();
      },
      err => {

        switch (err.error.message) {
          case 'incorrect':
            this.toastr.error(
              'Incorrect email or password',
              'Authentication failed'
            );
            break;
          case 'not activate':
            this.toastr.error(
              'You need activate your user before login',
              'Authentication failed'
            );
            break;
          default:
            this.toastr.error(err.message, 'Authentication failed');
        }

        this.service.loginModel.reset();
        this.stopWork();
      }
    );
  }
  startWork() {
    this.isWorking = true;
  }
  stopWork() {
    this.isWorking = false;
  }
}
