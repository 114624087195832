import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { ConstsService } from 'src/app/shared/consts.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  url = 'api/v1/admin';
  constructor(
    private httpClient: HttpClient,
    private storageService: LocalStorageService,
    private constService: ConstsService
  ) {}

  getAllPersons() {
    const token = this.storageService.get('token');

    // get all users from server
    return this.httpClient.post(
      `${this.constService.URL_DEVELOPMENT}${this.url}/getAllUsers`,
      null,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        })
      }
    );
  }

  getUsersReport() {
    return this.httpClient.get(
      `${this.constService.URL_DEVELOPMENT}${this.url}/exportUsersList`
    );
  }
}
