import { User } from "src/app/models/user.model";
import { Component, OnInit } from "@angular/core";
import { UserService } from "../shared/user.service";
import { Router } from "@angular/router";
import { ShiftService } from "../services/shift-service/shift.service";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../services/auth-service/auth.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
  user: any;
  constructor(
    public authService: AuthService,
    public shiftService: ShiftService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}
}
