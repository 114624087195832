import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  isWorking = false;
  constructor(public userService: UserService, private router: Router) {}

  ngOnInit() {
    // check if user connected or not
    this.userService.isLogin().subscribe(
      (res: any) => {
        if (res.status === 'success') {
          this.router.navigateByUrl('home');
          return true;
        }
      },
      err => {
        return false;
      }
    );
    return true;
  }
  startWork() {
    this.isWorking = true;
  }
  stopWork() {
    this.isWorking = false;
  }
}
