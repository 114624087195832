export class User {
  userId: number;
  email: string;
  token: string;
  firstName: string;
  lastName: string;
  shifts: [
    {
      date: Date;
      checkIn: Date;
      checkOut: Date;
      shiftTime: string;
      location: {
        longitude: number;
        latitude: number;
      };
    }
  ];
  todayShifts: [];
  role: string;

  constructor() {}
}
