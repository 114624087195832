import { MomentService } from './../services/moment/moment.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AdminService } from '../services/admin/admin.service';
import { User } from '../models/user.model';
import { Subject } from 'rxjs';
import { ShiftService } from '../services/shift-service/shift.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  monthlyShifts = [];
  eventChangeMonth = new Subject<any[]>();
  selectedUser: User;
  users: User[];
  constructor(
    private adminService: AdminService,
    public shiftService: ShiftService,
    public momentService: MomentService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getAllPersons();
  }

  getAllPersons() {
    this.adminService.getAllPersons().subscribe((res: any) => {
      this.users = res.users;
    });
  }

  change() {
    this.eventChangeMonth.next(this.selectedUser.shifts);
    // this.eventChangeUser.next(this.selectedUser);
    this.shiftService.selectedMonth = 1;
    this.monthlyShifts = null;
    this.getAllShiftHours();
    this.cd.detectChanges();
  }

  onChangeMonth(val) {
    this.monthlyShifts = this.selectedUser.shifts.filter(shift => {
      return (
        this.momentService.getMountFormat(shift.date) ===
        this.shiftService.selectedMonth.toString()
      );
    });
    this.shiftService.selectedMonth.toString();

    this.eventChangeMonth.next(this.monthlyShifts);
    // this.eventChangeUser.next(this.selectedUser);
    this.getAllShiftHours();
    this.cd.detectChanges();
  }

  getTotalShiftsTime() {
    let sum = 0;
    if (this.monthlyShifts) {
      this.monthlyShifts.map(shift => {
        if (shift.checkOut) sum += parseFloat(shift.shiftTime);
        else {
          return;
        }
      });
    }
    return sum;
  }

  getAllShiftHours() {
    this.selectedUser.shifts.map(shift => {
      if (shift.checkOut) {
        shift.shiftTime = parseFloat(
          this.momentService.getShiftTime(shift.checkIn, shift.checkOut)
        ).toFixed(2);
      } else {
        return;
      }
    });
  }

  getUsersReport() {
    this.adminService.getUsersReport().subscribe(res => {});
  }

  getShiftsReports() {
    this.shiftService.getUserReport(this.monthlyShifts).subscribe(
      res => {},
      err => {}
    );
  }
  getShiftLocation(data) {
    console.log(this.monthlyShifts[data]);
    let place = [this.monthlyShifts[data]];
    this.eventChangeMonth.next(place);
  }

  getAllShiftsLocations() {
    this.eventChangeMonth.next(this.monthlyShifts);
  }
}
