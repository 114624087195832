import { MomentService } from './../../services/moment/moment.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ShiftService } from 'src/app/services/shift-service/shift.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-clock-manager',
  templateUrl: './clock-manager.component.html',
  styleUrls: ['./clock-manager.component.css']
})
export class ClockManagerComponent implements OnInit {
  checkInTime = '';
  isNote = false;
  checkOutTime = '';
  user: any;
  constructor(
    public authService: AuthService,
    public shiftService: ShiftService,
    public momentService: MomentService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.user = this.authService.getActiveUser();
    if (this.user.shifts) {
      if (
        this.user.shifts[this.user.shifts.length - 1].checkIn &&
        !this.user.shifts[this.user.shifts.length - 1].checkOut
      ) {
        this.checkInTime = this.user.shifts[
          this.user.shifts.length - 1
        ].checkIn;
        this.isNote = true;
      } else {
        this.checkInTime = null;
        this.isNote = false;
      }
    }
  }

  checkIn() {
    this.shiftService.checkIn().subscribe(
      res => {
        if (res.user.shifts) {
          this.checkInTime =
            res.user.shifts[res.user.shifts.length - 1].checkIn;
          this.shiftService.MounthShifts = res.user.shifts.map(shift => {
            return (
              this.momentService.getMountFormat(shift.date) ===
              this.shiftService.selectedMonth.toString()
            );
          });
        }
        this.toastr.success('נכנסת בהצלחה למערכת');
        this.isNote = true;
      },
      err => {
        this.toastr.error('אתה עדיין במשמרת , בצע יציאה ואז כניסה!');
      }
    );
    this.shiftService.selectedMonth = 1;
  }
  checkOut() {
    this.shiftService.checkOut().subscribe(
      res => {
        this.toastr.success('יצאת מהמשמרת בהצלחה ! יום טוב!');
        this.checkInTime = null;
        this.shiftService.note = '';
        this.isNote = false;
      },
      err => {
        this.toastr.error('עדיין לא נכנסת למערכת, אנא בצע כניסה .');
      }
    );
    this.shiftService.selectedMonth = 1;
  }
}
