import { EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.prod';

import { GeoJson, FeatureCollection } from '../../map';
import * as mapboxgl from 'mapbox-gl';
import { ShiftService } from '../shift-service/shift.service';
import { AuthService } from '../auth-service/auth.service';
import { User } from 'src/app/models/user.model';
import { MomentService } from '../moment/moment.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  style = 'mapbox://styles/mapbox/outdoors-v9';
  markers: any = {};
  monthMarker: any = [];
  activeUser: User;
  coor: number[];
  map: any;
  constructor(
    private shiftService: ShiftService,
    private momentService: MomentService,
    private authService: AuthService
  ) {
    mapboxgl.accessToken = environment.mapbox.accessToken;
    this.markers.features = [];
  }

  getValue() {
    return 'map value';
  }

  getMarkers(month: number) {
    this.activeUser = this.authService.getActiveUser();
    this.activeUser.shifts.map(shift => {
      this.markers.features.push({
        type: 'Feature',
        properties: {
          id: 'ak16994519',
          mag: 1.7,
          time: 1507425289659,
          felt: null,
          tsunami: 0
        },
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(shift.location.longitude.toString()),
            parseFloat(shift.location.latitude.toString())
          ]
        }
      });
    });
    this.markers.type = 'FeatureCollection';

    this.markers.features.map();
    this.monthMarker = this.markers;
    this.monthMarker.features.filter(feature => {
      return (
        this.momentService.getMountFormat(feature.date) ===
        this.shiftService.selectedMonth.toString()
      );
    });

    this.setLayoutHeatMap(this.map, this.monthMarker);
  }
  build = month => {
    const fColl = this.createGeoJson(this.coor);
    this.setLayoutCurrentLocation(this.map, fColl);
    this.getMarkers(month);
  };

  setInit(map: any, month: number) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.coor = [pos.coords.longitude, pos.coords.latitude];
        this.map = this.createHeatMap(this.coor);
        this.map.on('load', this.build(month));
      });
    }
  }

  setInitByMonth(map: any, arr: any[]) {
    this.map = map;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.coor = [pos.coords.longitude, pos.coords.latitude];
        this.map = this.createHeatMap(this.coor);
        this.map.on('load', () => {
          const collection = this.createFeatureCollection(
            arr.map(loc => {
              loc.latitude = parseFloat(loc.latitude);
              loc.longitude = parseFloat(loc.longitude);
              return [loc.longitude, loc.latitude];
            })
          );

          this.setLayoutHeatMap(this.map, collection);
        });
      });
    }
  }

  removeSource(map: any) {
    if (map.getLayer('earthquakes-heat')) {
      map.removeLayer('earthquakes-heat');
    }
    if (map.getLayer('earthquakes-point')) {
      map.removeLayer('earthquakes-point');
    }
    map.removeSource('earthquakes');
  }
  buildMap(map: any, location: number[]) {

    const fColl = this.createGeoJson(location);
  }

  createMap(location: number[], zoom?: number) {
    return new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v8',
      center: location,
      zoom: zoom ? zoom : 13
    });
  }

  createHeatMap(location: number[]) {
    return new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/dark-v10',
      center: location,
      zoom: 6
    });
  }

  setLayoutHeatMap(map: mapboxgl.Map, collection: FeatureCollection) {
    map.addSource('earthquakes', {
      type: 'geojson',
      data: collection
    });

    map.addLayer(
      {
        id: 'earthquakes-heat',
        type: 'heatmap',
        source: 'earthquakes',
        maxzoom: 9,
        paint: {
          // Increase the heatmap weight based on frequency and property magnitude
          'heatmap-weight': [
            'interpolate',
            ['linear'],
            ['get', 'mag'],
            0,
            0,
            6,
            1
          ],
          // Increase the heatmap color weight weight by zoom level
          // heatmap-intensity is a multiplier on top of heatmap-weight
          'heatmap-intensity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0,
            1,
            9,
            3
          ],
          // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
          // Begin color ramp at 0-stop with a 0-transparancy color
          // to create a blur-like effect.
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(33,102,172,0.1)',
            0.2,
            'rgb(103,169,207)',
            0.4,
            'rgb(209,229,240)',
            0.6,
            'rgb(253,219,199)',
            0.8,
            'rgb(239,138,98)',
            1,
            'rgb(178,24,43)'
          ],
          // Adjust the heatmap radius by zoom level
          'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 20],
          // Transition from heatmap to circle layer by zoom level
          'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 9, 0]
        }
      },
      'waterway-label'
    );

    map.addLayer(
      {
        id: 'earthquakes-point',
        type: 'circle',
        source: 'earthquakes',
        minzoom: 7,
        paint: {
          // Size circle radius by earthquake magnitude and zoom level
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            7,
            ['interpolate', ['linear'], ['get', 'mag'], 1, 1, 6, 4],
            16,
            ['interpolate', ['linear'], ['get', 'mag'], 1, 5, 6, 50]
          ],
          // Color circle by earthquake magnitude
          'circle-color': [
            'interpolate',
            ['linear'],
            ['get', 'mag'],
            1,
            'rgba(33,102,172,0)',
            2,
            'rgb(103,169,207)',
            3,
            'rgb(209,229,240)',
            4,
            'rgb(253,219,199)',
            5,
            'rgb(239,138,98)',
            6,
            'rgb(178,24,43)'
          ],
          'circle-stroke-color': 'white',
          'circle-stroke-width': 1,
          // Transition from heatmap to circle layer by zoom level
          'circle-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 1]
        }
      },
      'waterway-label'
    );
  }

  createGeoJson(location: number[]) {
    return new GeoJson(location, {
      id: 'ak16990501',
      mag: 2.8,
      time: 1507302735109,
      felt: null,
      tsunami: 0
    });
  }

  createFeatureCollection(location: Array<number[]>) {
    return new FeatureCollection(
      location.map(location => this.createGeoJson(location))
    );
  }

  setLayoutCurrentLocation(map: any, location: GeoJson) {
    const marker = new mapboxgl.Marker(null);
    marker.setLngLat(location.geometry.coordinates);
    marker.addTo(this.map);
  }
}
