import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  isWorking = false;

  constructor(public service: UserService, private toastr: ToastrService) {}

  ngOnInit() {}

  onRegisterClick() {
    this.startWork();

    this.service.register().subscribe(
      res => {
        this.toastr.success(
          'New User Created , We send to your mail an activation code.',
          'Registration successful'
        );
        this.service.formModel.reset();
        this.service.loginModel.reset();
        this.stopWork();
        this.service.navigateToLogin();
      },
      err => {
        switch (err.error.message) {
          case 'email exist':
            this.toastr.error('Email is already taken.');
            break;
          default:
            this.toastr.error(err.message);
        }
        this.stopWork();
        this.service.formModel.patchValue({
          Passwords: {
            Password: '',
            ConfirmPassword: ''
          }
        });
      }
    );
  }

  startWork() {
    this.isWorking = true;
  }
  stopWork() {
    this.isWorking = false;
  }
}
