import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/user.model";
import { AuthService } from "src/app/services/auth-service/auth.service";

@Component({
  selector: "app-personal",
  templateUrl: "./personal.component.html",
  styleUrls: ["./personal.component.css"]
})
export class PersonalComponent implements OnInit {
  user: User;
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.user = this.authService.getActiveUser();
  }
}
