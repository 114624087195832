import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from 'src/app/shared/user.service';
import { LocalStorageService } from 'ngx-localstorage';
import { User } from 'src/app/models/user.model';
import { tap } from 'rxjs/operators';
import { ConstsService } from 'src/app/shared/consts.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {
  readonly base_url = 'api/v1/shift';
  readonly admin_url = 'api/v1/admin';

  months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  lat = 51.678418;
  lng = 7.809007;
  note = '';

  activeUser: User = null;
  userShifts: User = null;
  MounthShifts = [];
  selectedMonth: number = 1;

  constructor(
    private http: HttpClient,
    private storageService: LocalStorageService,
    private constService: ConstsService
  ) {
    navigator.geolocation.getCurrentPosition(pos => {
      this.lat = pos.coords.latitude;
      this.lng = pos.coords.longitude;
    });
  }

  getUserShifts() {
    const json = this.storageService.get('userShifts');
    this.userShifts = JSON.parse(json);
    return this.userShifts;
  }

  checkIn() {
    const activeUser = JSON.parse(this.storageService.get('activeUser'));

    return this.http
      .post(
        `${this.constService.URL_DEVELOPMENT}${this.base_url}/checkIn`,
        {
          userId: activeUser.userId,
          checkIn: Date.now(),
          latitude: this.lat,
          longitude: this.lng
        },
        {
          withCredentials: true
        }
      )
      .pipe(
        tap((res: any) => {
          this.activeUser = res.user;
          const activeUser = JSON.stringify(this.activeUser);
          this.storageService.set('activeUser', activeUser);

          return res;
        })
      );
  }
  checkOut() {
    const activeUser = JSON.parse(this.storageService.get('activeUser'));
    return this.http
      .post(
        `${this.constService.URL_DEVELOPMENT}${this.base_url}/checkOut`,
        {
          userId: activeUser.userId,
          checkOut: Date.now(),
          note: this.note
        },
        {
          withCredentials: true
        }
      )
      .pipe(
        tap((res: any) => {
          this.activeUser = res.user;
          this.userShifts = res.userShifts;
          const activeUser = JSON.stringify(this.activeUser);
          this.storageService.set('activeUser', activeUser);
          return res;
        })
      );
  }

  getUserReport(arr: any[]) {
    const token = this.storageService.get('token');
    const bodyJson = JSON.stringify(
      arr.map(res => {
        return {
          checkOut: res.checkOut,
          checkIn: res.checkIn,
          date: res.date,
          shiftTime: res.shiftTime
        };
      })
    );

    const authorization = `Bearer ${token}`;

    return this.http.get<any>(
      `${this.constService.URL_DEVELOPMENT}${this.admin_url}/exportUserReport`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'appliction/json',
          authorization,
          bodyJson
        })
      }
    );
  }
}
