import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { UserService } from '../shared/user.service';
import { AuthService } from '../services/auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public userService: UserService,
    private router: Router,
    private authService: AuthService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    this.userService.isLogin().subscribe(
      (res: any) => {
        if (res.status === 'success') {
          const user = this.authService.getActiveUser();
          if (user.role === 'admin') {
            this.router.navigateByUrl('admin');
          } else if (user.role === 'user') return true;
        }
      },
      err => {
        this.router.navigateByUrl('user/login');
        return false;
      }
    );

    return true;
  }
}
