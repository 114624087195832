import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from 'src/app/models/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'ngx-localstorage';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ConstsService } from 'src/app/shared/consts.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly base_url = 'api/v1/users';
  activeUser: User = null;
  loginModel = this.fb.group({
    Email: ['', [Validators.required, Validators.email]],
    Password: ['', [Validators.required, Validators.minLength(6)]]
  });

  formModel = this.fb.group({
    Email: ['', [Validators.required, Validators.email]],
    FirstName: ['', Validators.required],
    LastName: ['', Validators.required],
    Passwords: this.fb.group(
      {
        Password: ['', [Validators.required, Validators.minLength(6)]],
        ConfirmPassword: ['', Validators.required]
      },
      { validator: this.comparePassword }
    )
  });
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private storageService: LocalStorageService,
    private router: Router,
    private constsService: ConstsService
  ) {}

  comparePassword(fb: FormGroup) {
    const confirmPasswordCtrl = fb.get('ConfirmPassword');
    // passwordMismatch

    // confirmPasswordCtrl.errors = {passwordMismatch:true}
    if (
      confirmPasswordCtrl.errors == null ||
      'passwordMismatch' in confirmPasswordCtrl.errors
    ) {
      if (fb.get('Password').value !== confirmPasswordCtrl.value) {
        confirmPasswordCtrl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordCtrl.setErrors(null);
      }
    }
  }

  register() {
    const body = {
      email: this.formModel.value.Email,
      firstName: this.formModel.value.FirstName,
      lastName: this.formModel.value.LastName,
      password: this.formModel.value.Passwords.Password
    };
    return this.http.post(
      `${this.constsService.URL_DEVELOPMENT}${this.base_url}/signup`,
      body
    );
  }

  login() {
    const body = {
      email: this.loginModel.value.Email,
      password: this.loginModel.value.Password
    };
    return this.http
      .post(
        `${this.constsService.URL_DEVELOPMENT}${this.base_url}/login`,
        body,
        {
          withCredentials: true
        }
      )
      .pipe(
        tap((res: any) => {
          this.activeUser = res.user;
          const userJson = JSON.stringify(this.activeUser);
          this.storageService.set('activeUser', userJson);
          return res;
        })
      );
  }

  logOut() {
    this.storageService.remove('token');
    this.storageService.remove('activeUser');
    this.activeUser = null;
    this.router.navigateByUrl('user/login');
  }

  // check if user still login
  isLogin() {
    const token = this.storageService.get('token');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('authorization', 'Bearer ' + token);
    return this.http.get(this.base_url + '/isLogin', { headers });
  }

  getActiveUser() {
    const json = this.storageService.get('activeUser');
    this.activeUser = JSON.parse(json);
    return this.activeUser;
  }
}
