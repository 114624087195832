import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MomentService {
  constructor() {}

  // Get current time(HH:MM)
  getTimeFormat(time: any) {
    if (!time) return null;
    return moment(time).format('HH:mm');
  }

  // Get current month(MM)
  getMountFormat(time: Date) {
    if (!time) {
      return null;
    } else {
      return moment(time).format('MM');
    }
  }

  // Get shift time(checkin - check out)
  getShiftTime(start: Date, finish: Date) {
    const now = moment(start); //todays date
    const end = moment(finish); // another date
    const duration = moment.duration(end.diff(now));
    const hours = duration.asHours().toFixed(2);
    return hours;
  }

  // Get date format(DD/MM/YYYY)
  getDateFormat(date: Date) {
    return moment(date).format('DD/MM/YYYY');
  }
}
