import { Component, OnInit, Input } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { MapService } from '../../services/map/map.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-map-box',
  templateUrl: './map-box.component.html',
  styleUrls: ['./map-box.component.css']
})
export class MapBoxComponent implements OnInit {
  // default setting
  map: mapboxgl.Map;
  @Input() month: number;

  @Input() onUpdateMonth: Observable<any[]>;

  constructor(private mapService: MapService) {}

  ngOnInit() {
    this.mapService.setInit(this.map, this.month);

    this.onUpdateMonth.subscribe((arr: any[]) => {
      const arrLocations = arr.map(x => x.location);
      this.mapService.setInitByMonth(this.map, arrLocations);
    });
  }
}
