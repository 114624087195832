import { AdminComponent } from './admin/admin.component';
import { ClockManagerComponent } from './home/clock-manager/clock-manager.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './user/register/register.component';
import { LoginComponent } from './user/login/login.component';
import { UserComponent } from './user/user.component';
import { ActivationComponent } from './user/activation/activation.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth/auth.guard';
import { UserShiftsComponent } from './home/reports/user-shifts/user-shifts.component';

const routes: Routes = [
  { path: '', redirectTo: '/user/login', pathMatch: 'full' },
  {
    path: 'user',
    component: UserComponent,
    children: [
      { path: 'register', component: RegisterComponent },
      { path: 'login', component: LoginComponent },
      { path: 'activation', component: ActivationComponent }
    ]
  },
  {
    path: 'home',
    // ClockManagerComponent
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'clockManager', pathMatch: 'full' },
      { path: 'userShift', component: UserShiftsComponent },
      { path: 'clockManager', component: ClockManagerComponent }
    ]
  },
  {
    path: 'admin',
    // ClockManagerComponent
    component: AdminComponent,
    // canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: AdminComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
